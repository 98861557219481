/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 21.659c0-.741.6-1.342 1.341-1.342H21.66a1.341 1.341 0 1 1 0 2.683H2.34C1.601 23 1 22.4 1 21.66M12 18.708c.74 0 1.342-.601 1.342-1.342V5.58l4.149 4.15a1.341 1.341 0 1 0 1.897-1.898l-6.44-6.439a1.34 1.34 0 0 0-1.897 0l-6.439 6.44A1.341 1.341 0 1 0 6.51 9.728l4.149-4.149v11.786c0 .741.6 1.342 1.341 1.342"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgUpload: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-upload", props.className)} />
));

export default SvgUpload;
