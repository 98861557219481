/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 3a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2h-.5v13a1 1 0 0 1-1 1h-6.086l2.293 2.293a1 1 0 0 1-1.414 1.414L12 18.414l-3.293 3.293a1 1 0 0 1-1.414-1.414L9.586 18H3.5a1 1 0 0 1-1-1V4H2a1 1 0 0 1-1-1m3.5 1v12h15V4zm13.19 2.303a1 1 0 0 1 .001 1.414l-4.979 4.99a1 1 0 0 1-1.407.008l-1.523-1.49-2.122 2.072a1 1 0 0 1-1.397-1.43l2.822-2.755a1 1 0 0 1 1.397 0l1.514 1.481 4.28-4.289a1 1 0 0 1 1.414-.001"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSlide: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-slide", props.className)} />
));

export default SvgSlide;
