import { SearchPaginationQuery } from ".";
import api from "./apiClient";
import { Carrier, ListResponse, Role, Store, TourOrderDriverType, User } from "./types";

export interface UserCreatePayload {
    firstName: User["firstName"];
    lastName: User["lastName"];
    role: User["role"];
    phone: User["phone"];
    carrier: User["carrier"];
}

export type UserUpdatePayload = Partial<User>;

export type UserListPayload = SearchPaginationQuery & {
    role?: Role["id"] | Role["id"][];
    carrier?: Carrier["id"];
    store?: Store["id"];
};
export type UserDetailsPayload = User["id"];
export type UserIdPayload = User["id"];

export interface DriverTypeResponse {
    type?: TourOrderDriverType;
}

export interface DriverTypePayload {
    id: User["id"];
    availableFrom: string;
    availableTo: string;
}

export const driverType = async ({ id, ...payload }: DriverTypePayload) => {
    return await api
        .get<DriverTypeResponse>(`/driverType/${id}`, { params: payload })
        .then((response) => response?.data);
};

export const details = async (payload?: UserDetailsPayload) => {
    if (!payload) {
        throw new Error("missing id");
    }

    return await api.get<User>(`/users/${payload}`).then((response) => response?.data);
};

export const create = async (payload?: UserCreatePayload) => {
    return await api.post(`/users`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: UserUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<User>(`/users/${id}`, payload).then((response) => response?.data);
};

export const remove = async (id?: UserDetailsPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/users/${id}`).then((response) => response?.data);
};

export type UserslistResponse = ListResponse<User>;
export const list = async (payload: UserListPayload = {}) => {
    return await api.get<UserslistResponse>("/users", { params: payload }).then((response) => response?.data);
};

export const regenerateToken = async (id: UserIdPayload) => {
    return await api.post<User>(`/users/${id}/regenerateToken`).then((response) => response.data);
};
