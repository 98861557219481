/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11a11 11 0 0 1-4.137-.805l-.246-.097H7.61l-.201.033-3.914.652-.032.005a7 7 0 0 1-.546.075 1.7 1.7 0 0 1-.8-.116 1.65 1.65 0 0 1-.865-.866 1.7 1.7 0 0 1-.116-.8c.013-.17.046-.369.075-.545l.006-.032.652-3.914.032-.201v-.005q-.001-.008-.005-.015l-.092-.232A11 11 0 0 1 1 12m11-8.8A8.8 8.8 0 0 0 3.2 12c0 1.173.229 2.29.643 3.31l.02.049c.067.164.147.36.188.543.037.165.053.31.053.48a3 3 0 0 1-.064.57l-.602 3.61 3.61-.602.042-.007c.153-.025.341-.057.528-.057.17 0 .315.016.48.053.183.041.379.12.543.188l.05.02A8.8 8.8 0 0 0 12 20.8a8.8 8.8 0 0 0 0-17.6M5.4 12a1.65 1.65 0 1 1 3.3 0 1.65 1.65 0 0 1-3.3 0m4.95 0a1.65 1.65 0 1 1 3.3 0 1.65 1.65 0 0 1-3.3 0m4.95 0a1.65 1.65 0 1 1 3.3 0 1.65 1.65 0 0 1-3.3 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMessage: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-message", props.className)} />
));

export default SvgMessage;
