import Button from "@oneui/button";
import { InputNumber, Space } from "antd";
import { FC, ReactNode, useEffect, useState } from "react";

import { Minus, Plus } from "./icons";

interface ControledInputProps {
    value?: number;
    onChange?: (count?: number) => void;
    suffix?: ReactNode;
    min?: number;
    max?: number;
}

const ControledInputNumber: FC<ControledInputProps> = ({ value, onChange, suffix = null, min, max }) => {
    const [count, setCount] = useState<number | undefined>(value || 0);
    const hasMin = min !== undefined;
    const hasMax = max !== undefined;

    const change = (n: number) => {
        if ((hasMin && n === -1 && count === min) || (hasMax && n === 1 && count === max)) {
            return;
        }
        const newCount = (count || 0) + n;
        setCount(newCount);
        onChange?.(newCount);
    };

    useEffect(() => {
        setCount(value);
    }, [value]);

    return (
        <Space direction="horizontal" size={16}>
            <Button
                type="secondary"
                onClick={() => change(-1)}
                iconOnly={<Minus />}
                disabled={hasMin && count !== undefined && count === min}
            />
            <InputNumber
                controls={false}
                value={value}
                min={min}
                max={max}
                onChange={(value) => {
                    const newValue = value === null ? undefined : value;
                    setCount(newValue);
                    onChange?.(newValue);
                }}
                className="!text-base"
            />
            <Button
                type="secondary"
                onClick={() => change(1)}
                iconOnly={<Plus />}
                disabled={hasMax && count !== undefined && count === max}
            />
            {suffix}
        </Space>
    );
};

export default ControledInputNumber;
