/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.884 3.884a1.21 1.21 0 0 1 1.711 0L12 10.29l6.405-6.405a1.21 1.21 0 0 1 1.711 1.711L13.711 12l6.405 6.405a1.21 1.21 0 0 1-1.711 1.711L12 13.712l-6.405 6.404a1.21 1.21 0 1 1-1.711-1.71L10.289 12 3.884 5.595a1.21 1.21 0 0 1 0-1.71"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.51 3.51a1.74 1.74 0 0 1 2.46 0L12 9.54l6.03-6.03a1.74 1.74 0 1 1 2.46 2.46L14.46 12l6.03 6.03a1.74 1.74 0 0 1-2.46 2.46L12 14.46l-6.03 6.03a1.74 1.74 0 0 1-2.46-2.46L9.54 12 3.51 5.97a1.74 1.74 0 0 1 0-2.46M10.288 12l-6.405 6.405a1.21 1.21 0 1 0 1.711 1.711L12 13.712l6.405 6.404a1.21 1.21 0 0 0 1.711-1.71L13.711 12l6.405-6.405a1.21 1.21 0 0 0-1.711-1.71L12 10.288 5.595 3.884a1.21 1.21 0 1 0-1.711 1.711z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgXClose: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-xclose", props.className)} />
));

export default SvgXClose;
