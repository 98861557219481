/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M22.18 4.81a1.15 1.15 0 0 1 0 1.638L9.32 19.19a1.177 1.177 0 0 1-1.654 0l-5.845-5.792a1.15 1.15 0 0 1 0-1.638 1.177 1.177 0 0 1 1.653 0l5.019 4.973L20.526 4.81a1.177 1.177 0 0 1 1.653 0"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.82 11.76a1.177 1.177 0 0 1 1.654 0l5.019 4.973L20.526 4.81a1.177 1.177 0 0 1 1.653 0 1.15 1.15 0 0 1 0 1.638L9.32 19.19a1.177 1.177 0 0 1-1.653 0l-5.845-5.792a1.15 1.15 0 0 1 0-1.638m6.673 4.305-4.68-4.636a1.66 1.66 0 0 0-2.332 0 1.616 1.616 0 0 0 0 2.301l5.846 5.792a1.66 1.66 0 0 0 2.332 0L22.519 6.78a1.616 1.616 0 0 0 0-2.301 1.66 1.66 0 0 0-2.332 0z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCheck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-check", props.className)} />
));

export default SvgCheck;
