import { VFC, useCallback } from "react";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";

import DatePicker from "./DatePicker";
import { ArrowRight, Calendar } from "./icons";

const { RangePicker } = DatePicker;

type DateRangePickerProps = RangePickerProps<Dayjs> & {
    value?: [string, string];
    onChange?: (values: [string, string]) => void;
    defaultValue?: [Dayjs, Dayjs];
};

const DateRangePicker: VFC<DateRangePickerProps> = ({ defaultValue, value, onChange, ...props }) => {
    const from = value ? dayjs(value?.[0]) : undefined;
    const to = value ? dayjs(value?.[1]) : undefined;
    const onSelectRange: RangePickerProps<Dayjs>["onChange"] = useCallback(
        (values) => {
            const [from, to] = values;
            onChange?.([from.toISOString(), to.toISOString()]);
        },
        [onChange]
    );
    return (
        <RangePicker
            picker="date"
            value={from && to ? [from, to] : undefined}
            defaultValue={defaultValue}
            format={"DD/MM/YYYY"}
            onChange={onSelectRange}
            suffixIcon={<Calendar className="text-base" />}
            separator={<ArrowRight className="text-base" />}
            {...props}
        />
    );
};

export default DateRangePicker;
