import { FC } from "react";

import { classNames } from "../helpers";
import "../assets/styles/components/ColorTag.less";
export enum ColorTagColor {
    green = "green",
    greenRedDashed = "green-red-dashed",
    purple = "purple",
    blue = "blue",
    darkBlue = "dark-blue",
    darkBlueFull = "dark-blue-full",
    orange = "orange",
    red = "red",
    grey = "grey",
    white = "white",
}
export interface ColorTagProps extends React.HTMLAttributes<HTMLSpanElement> {
    color?: ColorTagColor;
    size?: "default" | "small";
}

const ColorTag: FC<ColorTagProps> = ({
    color = ColorTagColor.white,
    size = color === ColorTagColor.white ? "small" : "default",
    children,
    className,
    ...props
}) => {
    return (
        <span
            {...props}
            className={classNames(
                "color-tag font-medium whitespace-nowrap",
                `color-tag-${color}`,
                size === "small" && "color-tag-small",
                className
            )}
        >
            {children}
        </span>
    );
};
export default ColorTag;
