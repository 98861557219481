/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.505 17.483c.673.69 1.765.69 2.438 0L12 10.261l7.057 7.222c.673.69 1.765.69 2.438 0a1.794 1.794 0 0 0 0-2.495l-8.276-8.47a1.696 1.696 0 0 0-2.438 0l-8.276 8.47a1.794 1.794 0 0 0 0 2.495"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevronUp: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron-up", props.className)} />
));

export default SvgChevronUp;
