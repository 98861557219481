/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M11.508 13.002q.144 0 .28.002l.512.008.458.014.409.021c.964.063 1.522.202 2.103.498a5 5 0 0 1 2.185 2.185l.049.098.089.194.078.194.034.098.063.205q.015.052.027.106l.05.226.02.12.037.258.03.286.023.32.017.356.017.618.01 1.002.001 1.19a1 1 0 0 1-1 1H2A1 1 0 0 1 1 21v-1.212l.004-.575.008-.513.014-.458.02-.409c.063-.964.203-1.522.499-2.103a5 5 0 0 1 2.185-2.185c.62-.316 1.213-.453 2.302-.51l.432-.017.485-.01.543-.006zm8.762.543a5 5 0 0 1 2.185 2.185l.095.195.043.097.077.194.035.098.063.205q.014.052.027.106l.05.226q.02.117.04.246l.033.271.026.303.028.52.017.618.009.73L23 21a1 1 0 0 1-1.993.117L21 21v-1.127l-.01-.955-.015-.567-.015-.318-.02-.275-.023-.238-.015-.107-.032-.192a3 3 0 0 0-.06-.245l-.05-.143-.056-.131-.031-.064a3 3 0 0 0-1.127-1.209l-.184-.102a1 1 0 0 1 .908-1.782m-8.797 1.457H7.528l-.697.008-.574.018-.321.018-.28.024-.243.03-.21.037a2.5 2.5 0 0 0-.349.096l-.147.06-.069.034a3 3 0 0 0-1.376 1.452l-.056.155a3 3 0 0 0-.07.268l-.036.211-.03.243-.024.28-.026.5-.014.612L3 20h12.999l-.003-.637-.006-.448-.015-.568-.015-.319-.02-.275-.024-.238-.03-.206-.036-.18-.02-.081-.046-.15-.053-.136-.058-.124a3 3 0 0 0-1.452-1.376l-.155-.056a3 3 0 0 0-.268-.069l-.211-.037-.243-.03-.28-.024-.5-.025-.611-.015zM9.5 2a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9m7.32.644A4.5 4.5 0 0 1 19 6.5a4.5 4.5 0 0 1-2.18 3.857 1 1 0 0 1-1.032-1.713A2.5 2.5 0 0 0 17 6.5c0-.887-.465-1.693-1.212-2.143a1 1 0 0 1 1.032-1.713M9.5 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPeoples: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-peoples", props.className)} />
));

export default SvgPeoples;
