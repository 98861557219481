/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M7.924 20.196c0 .445.355.804.795.804h6.56c.44 0 .795-.36.795-.804v-4.928h-8.15zM21.202 3H2.796c-.612 0-.995.671-.687 1.207l5.532 9.452h8.72l5.533-9.452C22.197 3.67 21.814 3 21.202 3"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgFilter: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-filter", props.className)} />
));

export default SvgFilter;
