/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M8.138 3.18h-.203c.112 0 .203-.096.203-.214zh7.724v-.215c0 .118.091.215.203.215h-.203v1.936h1.83V2.966c0-.95-.73-1.721-1.627-1.721h-8.13c-.897 0-1.626.771-1.626 1.72v2.152h1.83zm12.805 1.937H3.057c-.45 0-.813.384-.813.86v.86c0 .119.091.216.203.216h1.535l.627 14.063c.04.916.757 1.64 1.624 1.64h11.534c.87 0 1.583-.72 1.624-1.64l.627-14.063h1.535c.112 0 .203-.097.203-.215v-.86c0-.477-.363-.861-.813-.861M17.572 20.82H6.428L5.814 7.053h12.372z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.862 3.18h.203c-.112 0-.203-.096-.203-.214zH8.138v-.215c0 .118-.091.215-.203.215h.203v1.936h7.724zm-.244 1.692V3.425H8.382v1.447zm-9.31.245V2.966c0-.95.73-1.721 1.627-1.721h8.13c.897 0 1.626.771 1.626 1.72v2.152h3.252c.45 0 .813.384.813.86v.86c0 .119-.091.216-.203.216h-1.535l-.627 14.063c-.04.92-.755 1.64-1.624 1.64H6.233c-.867 0-1.583-.724-1.624-1.64L3.982 7.053H2.447c-.112 0-.203-.097-.203-.215v-.86c0-.477.363-.861.813-.861zm-2.56 2.18h-1.3A.454.454 0 0 1 2 6.837v-.86c0-.598.46-1.105 1.057-1.105h3.008V2.966C6.065 1.894 6.891 1 7.935 1h8.13c1.044 0 1.87.894 1.87 1.966v1.906h3.008c.597 0 1.057.507 1.057 1.105v.86c0 .241-.188.46-.447.46H20.25l-.617 13.83C19.588 22.164 18.778 23 17.767 23H6.233c-1.009 0-1.821-.838-1.867-1.873zm13.59 13.278.593-13.278H6.07l.593 13.278zm-10.91.245h11.144l.614-13.767H5.814z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgDelete: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-delete", props.className)} />
));

export default SvgDelete;
