import { FC } from "react";
import { Radio, RadioGroupProps } from "antd";

import { TourOrderStatus } from "../../../queries/api/types";

type ReturnedSupportsTypologySelectorProps = Omit<RadioGroupProps, "optionType" | "buttonStyle" | "options">;

const ReturnedSupportsTypologySelector: FC<ReturnedSupportsTypologySelectorProps> = ({ ...props }) => {
    const options: RadioGroupProps["options"] = [
        { label: "Tous les retours", value: "" },
        { label: "En retour", value: TourOrderStatus.onReturnWithSupports },
        { label: "En débord", value: TourOrderStatus.unloadingSupports },
        { label: "En déchargement", value: TourOrderStatus.overhanging },
        { label: "Déchargé", value: TourOrderStatus.completedWithSupportsUnloaded },
    ];

    return <Radio.Group {...props} optionType="button" buttonStyle="solid" options={options} />;
};

export default ReturnedSupportsTypologySelector;
