/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M5.007 18.6q.083 0 .165-.014l4.625-.81a.27.27 0 0 0 .146-.078L21.6 6.041a.274.274 0 0 0 0-.388L17.03 1.08a.27.27 0 0 0-.196-.08.27.27 0 0 0-.195.08L4.982 12.737a.3.3 0 0 0-.077.146l-.811 4.626a.92.92 0 0 0 .258.819c.182.176.41.272.655.272m1.853-4.796 9.974-9.971 2.016 2.015-9.974 9.972-2.445.432zm15.26 7.106H1.88a.88.88 0 0 0-.88.88v.99c0 .121.099.22.22.22h21.56a.22.22 0 0 0 .22-.22v-.99a.88.88 0 0 0-.88-.88"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgEdit: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-edit", props.className)} />
));

export default SvgEdit;
