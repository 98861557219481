/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <g clipPath="url(#alert-circle_svg__a)">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 3.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5M.75 12C.75 5.787 5.787.75 12 .75S23.25 5.787 23.25 12 18.213 23.25 12 23.25.75 18.213.75 12M12 6.75c.69 0 1.25.56 1.25 1.25v4a1.25 1.25 0 1 1-2.5 0V8c0-.69.56-1.25 1.25-1.25M10.75 16c0-.69.56-1.25 1.25-1.25h.01a1.25 1.25 0 0 1 0 2.5H12c-.69 0-1.25-.56-1.25-1.25"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="alert-circle_svg__a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgAlertCircle: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-alert-circle", props.className)} />
));

export default SvgAlertCircle;
