import { VFC, useCallback, useMemo, useState } from "react";
import { Empty, Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { useDebounce } from "../hooks";
import { useUsersList } from "../queries/users";
import { getFullName } from "../helpers";
import { useRoleList } from "../queries/roles";
import { RoleSlug } from "../queries/api/types";
import { UserListPayload } from "../queries/api/users";

interface SelectDriverProps extends Omit<SelectProps, "options" | "onSearch"> {
    additionalOptions?: DefaultOptionType[];
    queryPayload?: Omit<UserListPayload, "role">;
}

const SelectDriver: VFC<SelectDriverProps> = ({ additionalOptions = [], queryPayload, ...props }) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const search = useDebounce(query, 500);
    const { data: roleListData } = useRoleList({ slug: [RoleSlug.driver] });
    const driverRoleId = roleListData?.items[0]?.id;
    const { data: driverListData, isLoading } = useUsersList({
        pageSize: 20,
        role: driverRoleId,
        search,
        ...queryPayload,
    });
    const options: SelectProps["options"] = useMemo(() => {
        return (driverListData?.items || []).map((driver) => ({
            label: `${getFullName(driver)} ${
                driver.computedProperties?.carrierName ? `(${driver.computedProperties?.carrierName})` : ""
            }`,
            value: driver.id,
        }));
    }, [driverListData?.items]);
    const onSelect = useCallback(() => {
        setQuery(undefined);
    }, []);
    return (
        <Select
            options={[...additionalOptions, ...options]}
            showSearch
            onSearch={(query) => setQuery(query)}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSelect={onSelect}
            {...props}
        />
    );
};

export default SelectDriver;
