import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    create,
    details,
    list,
    StoreCreatePayload,
    StoreIdPayload,
    StoreListPayload,
    StoreListResponse,
    StoreUpdatePayload,
    remove,
    update,
    updateUsers,
} from "./api/stores";
import { Store } from "./api/types";
import { userKeys } from "./users";

export const storesKeys = {
    all: ["stores"],
    lists: () => [...storesKeys.all, "list"],
    list: (params?: StoreListPayload) => [...storesKeys.lists(), params],
    details: () => [...storesKeys.all, "details"],
    detail: (id?: StoreIdPayload) => [...storesKeys.details(), id],
};

export const useStoreList = <TData = StoreListResponse>(
    params?: StoreListPayload,
    options?: UseQueryOptions<StoreListResponse, AxiosError, TData>
) => {
    return useQuery<StoreListResponse, AxiosError, TData>(storesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useStoreDetails = <TData = Store>(
    id?: StoreIdPayload,
    options?: UseQueryOptions<Store, AxiosError, TData>
) => {
    return useQuery<Store, AxiosError, TData>(storesKeys.detail(id), async () => await details(id), options);
};

export const useStoreCreate = (options?: UseMutationOptions<Store, AxiosError, StoreCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Store, AxiosError, StoreCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(storesKeys.lists());
        },
    });
};

export const useStoreUpdate = (options?: UseMutationOptions<Store, AxiosError, StoreUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Store, AxiosError, StoreUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(storesKeys.detail(variables.id));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(storesKeys.lists());
        },
    });
};

export const useStoreRemove = (options?: UseMutationOptions<undefined, AxiosError, StoreIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, StoreIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(storesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(storesKeys.lists());
        },
    });
};

export const useStoreUpdateUsers = (options?: UseMutationOptions<undefined, AxiosError, StoreIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, StoreIdPayload>(async (params) => await updateUsers(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate users list query with specific store ID
            queryClient.invalidateQueries(userKeys.list({ store: variables }));

            // invalidate stores list query
            queryClient.invalidateQueries(storesKeys.lists());
        },
    });
};
