import { FC } from "react";
import { Card, CardProps } from "antd";

import "../assets/styles/components/CardBlueHeader.less";

import { classNames } from "../helpers";

const CardBlueHeader: FC<CardProps> = ({ className, ...props }) => {
    return <Card {...props} className={classNames("card-blue-header", className)} />;
};
export default CardBlueHeader;
