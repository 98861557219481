/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.792 2H8.29c.218 0 .396.17.396.378V3.51a.39.39 0 0 1-.396.377H3.981v4.151a.39.39 0 0 1-.396.377H2.396A.39.39 0 0 1 2 8.038V2.755C2 2.338 2.354 2 2.792 2m18.623 13.585h1.189c.218 0 .396.17.396.377v5.284c0 .417-.354.754-.793.754H16.71a.39.39 0 0 1-.396-.377V20.49c0-.208.178-.378.396-.378h4.309v-4.15c0-.208.178-.378.396-.378M3.981 20.113h4.31c.217 0 .395.17.395.378v1.132A.39.39 0 0 1 8.29 22H2.792C2.354 22 2 21.663 2 21.245v-5.283c0-.207.178-.377.396-.377h1.189c.218 0 .396.17.396.377zM16.71 2h5.497c.439 0 .793.338.793.755v5.283a.39.39 0 0 1-.396.377h-1.189a.39.39 0 0 1-.396-.377V3.887h-4.31a.39.39 0 0 1-.395-.377V2.378c0-.208.178-.378.396-.378M.952 10.572a.952.952 0 0 0 0 1.904h22.096a.952.952 0 1 0 0-1.904z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgScan: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-scan", props.className)} />
));

export default SvgScan;
