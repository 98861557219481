/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M10.74 1H1.917A.916.916 0 0 0 1 1.917v8.823c0 .126.103.229.23.229h9.51a.23.23 0 0 0 .229-.23V1.23a.23.23 0 0 0-.23-.229M9.135 9.136H2.833V2.834h6.302zm-3.953-2.12h1.604a.23.23 0 0 0 .23-.23V5.184a.23.23 0 0 0-.23-.23H5.182a.23.23 0 0 0-.229.23v1.604c0 .126.103.229.23.229m5.558 6.015H1.23a.23.23 0 0 0-.23.23v8.823c0 .507.41.916.917.916h8.823a.23.23 0 0 0 .229-.229v-9.51a.23.23 0 0 0-.23-.23m-1.605 8.136H2.833v-6.302h6.302zm-3.953-2.12h1.604a.23.23 0 0 0 .23-.229v-1.604a.23.23 0 0 0-.23-.23H5.182a.23.23 0 0 0-.229.23v1.604c0 .126.103.23.23.23M22.083 1H13.26a.23.23 0 0 0-.229.23v9.51c0 .126.103.229.23.229h9.51a.23.23 0 0 0 .229-.23V1.918A.916.916 0 0 0 22.083 1m-.916 8.136h-6.302V2.834h6.302zm-3.954-2.12h1.605a.23.23 0 0 0 .229-.23V5.184a.23.23 0 0 0-.23-.23h-1.604a.23.23 0 0 0-.229.23v1.604c0 .126.103.229.23.229m5.558 6.015h-1.375a.23.23 0 0 0-.23.23v3.838h-2.234v-3.838a.23.23 0 0 0-.229-.23H13.26a.23.23 0 0 0-.229.23v9.51c0 .126.103.23.23.23h1.374a.23.23 0 0 0 .23-.23v-6.99h2.234v2.922c0 .126.103.23.23.23h5.442a.23.23 0 0 0 .229-.23v-5.442a.23.23 0 0 0-.23-.23m-4.068 8.136h-1.375a.23.23 0 0 0-.229.23v1.374c0 .126.103.23.23.23h1.374a.23.23 0 0 0 .23-.23v-1.375a.23.23 0 0 0-.23-.23m4.068 0h-1.375a.23.23 0 0 0-.23.23v1.374c0 .126.104.23.23.23h1.375a.23.23 0 0 0 .229-.23v-1.375a.23.23 0 0 0-.23-.23"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgQrcode: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-qrcode", props.className)} />
));

export default SvgQrcode;
