import { VFC, useCallback, useMemo, useState } from "react";
import { Empty, Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { useDebounce } from "../hooks";
import { useCarrierList } from "../queries/carriers";
import { CarrierListPayload } from "../queries/api/carriers";

interface SelectCarrierProps extends Omit<SelectProps, "options" | "onSearch"> {
    additionalOptions?: DefaultOptionType[];
    queryPayload?: CarrierListPayload;
}

const SelectCarrier: VFC<SelectCarrierProps> = ({ additionalOptions = [], queryPayload, ...props }) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const search = useDebounce(query, 500);
    const { data: carrierListData, isLoading } = useCarrierList({
        pageSize: 20,
        search,
        ...queryPayload,
    });
    const options: SelectProps["options"] = useMemo(() => {
        return (carrierListData?.items || []).map((carrier) => ({ label: carrier.name, value: carrier.id }));
    }, [carrierListData?.items]);

    const onSelect = useCallback(() => {
        setQuery(undefined);
    }, []);

    return (
        <Select
            options={[...additionalOptions, ...options]}
            showSearch
            onSearch={(query) => setQuery(query)}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSelect={onSelect}
            {...props}
        />
    );
};

export default SelectCarrier;
