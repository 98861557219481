/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h.586l8.793-8.793a3 3 0 0 1 4.242 0L20 12.586V5a1 1 0 0 0-1-1zm17 11V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V15m-2 .415-2.793-2.793a1 1 0 0 0-1.414 0L8.414 20H19a1 1 0 0 0 1-1zM9 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2M6 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgImage: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-image", props.className)} />
));

export default SvgImage;
