/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.517 21.495a1.696 1.696 0 0 1 0-2.438L13.74 12 6.517 4.944a1.696 1.696 0 0 1 0-2.439 1.794 1.794 0 0 1 2.496 0l8.47 8.276c.69.673.69 1.765 0 2.438l-8.47 8.276a1.794 1.794 0 0 1-2.496 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevronRight: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron-right", props.className)} />
));

export default SvgChevronRight;
