/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="m22.958 10.092-.006-.023-3.296-8.279a1.13 1.13 0 0 0-1.08-.79H5.219c-.5 0-.943.328-1.084.802l-3.08 8.195-.01.02-.006.023c-.038.142-.05.288-.029.43a2 2 0 0 0-.006.14v10.625c0 .468.19.916.524 1.247.335.331.788.518 1.262.518h18.42c.984 0 1.786-.793 1.789-1.765V10.609q0-.057-.003-.107a1.1 1.1 0 0 0-.038-.41M14.27 8.844l-.009.456c-.023 1.303-.934 2.18-2.264 2.18-.65 0-1.207-.206-1.61-.598-.402-.392-.622-.938-.634-1.582l-.009-.456H3.7L6.035 3.23H17.76l2.4 5.614zm-11.013 2.23h4.62C8.59 12.732 10.107 13.71 12 13.71c.99 0 1.909-.273 2.652-.79a4.3 4.3 0 0 0 1.489-1.847h4.596v9.696H3.257z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgInbox: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-inbox", props.className)} />
));

export default SvgInbox;
