/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.116 5.116a1.25 1.25 0 0 1 1.768 0l6 6a1.25 1.25 0 0 1 0 1.768l-6 6a1.25 1.25 0 1 1-1.768-1.768l3.866-3.866H4a1.25 1.25 0 1 1 0-2.5h12.982l-3.866-3.866a1.25 1.25 0 0 1 0-1.768"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgArrowNarrowRight: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-arrow-narrow-right", props.className)} />
));

export default SvgArrowNarrowRight;
