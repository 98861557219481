import { Form, Input, InputProps } from "antd";
import { VFC, useCallback, useEffect } from "react";
import { FormProps, useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useModal } from "@ebay/nice-modal-react";
import Button from "@oneui/button";
import { SearchOutlined } from "@ant-design/icons";

import { Carrier, Store, TableTemplateKey, User } from "../../../queries/api/types";
import DateRangePicker from "../../../components/DateRangePicker";
import SelectStore from "../../../components/SelectStore";
import SelectCarrier from "../../../components/SelectCarrier";
import SelectDriver from "../../../components/SelectDriver";
import CustomizeTableDrawer from "../../../components/CustomizeTableDrawer";
import useQueryParams from "../../../hooks/queryParams";
import { Gear } from "../../../components/icons";
import { ActiveFiltersShape } from "./ReturnedSupportsList";
import ReturnedSupportsTypologySelector from "./ReturnedSupportsTypologySelector";

interface ReturnedSupportsListFiltersProps {
    activeFilters: ActiveFiltersShape;
}

interface ReturnedSupportsListFiltersFormShape {
    search: string;
    typology: string;
    dates: [string, string];
    store: Store["id"];
    carrier: Carrier["id"];
    driver: User["id"];
}

const customizeReturedSupportsListTableDrawerId = "customize-returned-supports-list-table-drawer";

let searchTimeout: number;

const ReturnedSupportsListFilters: VFC<ReturnedSupportsListFiltersProps> = ({ activeFilters }) => {
    const [form] = useForm();
    const [queryParams, setQueryParams] = useQueryParams("tour-orders-list");
    const typology = queryParams.get("typology") ?? undefined;
    const search = queryParams.get("search") ?? undefined;
    const from = queryParams.get("from") ?? dayjs().startOf("day").toISOString();
    const to = queryParams.get("to") ?? dayjs().add(1, "day").endOf("day").toISOString();
    const store = queryParams.get("store") ?? undefined;
    const carrier = queryParams.get("carrier") ?? undefined;
    const driver = queryParams.get("driver") ?? undefined;

    const onFiltersFormChange: FormProps<ReturnedSupportsListFiltersFormShape>["onChange"] = useCallback(() => {
        const filters = form.getFieldsValue();
        const { typology, dates, store, carrier, driver } = filters;
        const [from, to] = dates;
        setQueryParams({
            ...activeFilters,
            typology: !!typology ? typology : undefined,
            from: form.isFieldTouched("dates") ? from : undefined,
            to: form.isFieldTouched("dates") ? to : undefined,
            store: !!store ? store : undefined,
            carrier: !!carrier ? carrier : undefined,
            driver: !!driver ? driver : undefined,
        });
    }, [activeFilters, form, setQueryParams]);

    useEffect(() => {
        form.setFieldsValue({
            search: search || "",
            typology: typology || "",
            dates: [from, to],
            store: store || "",
            carrier: carrier || "",
            driver: driver || "",
        });
    }, [carrier, driver, form, from, search, store, to, typology]);

    const customizeTableDrawer = useModal(customizeReturedSupportsListTableDrawerId);

    const onSearch: InputProps["onChange"] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    ...activeFilters,
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [activeFilters, setQueryParams]
    );

    return (
        <Form<ReturnedSupportsListFiltersFormShape>
            size="small"
            form={form}
            name="tourOrdersFilters"
            onValuesChange={onFiltersFormChange}
            initialValues={{
                dates: [from, to],
                store,
                carrier,
                driver,
                typology,
            }}
        >
            <div className="flex gap-x-8 flex-wrap gap-y-16 mb-16">
                <Form.Item name="typology" className="!mb-0">
                    <ReturnedSupportsTypologySelector size="middle" />
                </Form.Item>
                <Form.Item noStyle name="search">
                    <Input
                        className="!flex-1 !min-w-128"
                        placeholder="Rechercher un n° d’OT..."
                        onChange={onSearch}
                        suffix={<SearchOutlined className="text-brand-blue-600 text-[1.25rem]" />}
                        allowClear
                    />
                </Form.Item>

                <Button type="secondary" iconOnly={<Gear />} onClick={() => customizeTableDrawer.show()} />
            </div>
            <div className="flex gap-x-8">
                <Form.Item className="!mb-0 min-w-[240px]" name="dates">
                    <DateRangePicker allowClear={false} />
                </Form.Item>
                <Form.Item className="!mb-0 flex-1" name="store">
                    <SelectStore additionalOptions={[{ label: "Tous les magasins", value: "" }]} />
                </Form.Item>
                <Form.Item className="!mb-0 flex-1" name="driver">
                    <SelectDriver
                        queryPayload={{ carrier }}
                        additionalOptions={[{ label: "Tous les chauffeurs", value: "" }]}
                    />
                </Form.Item>
                <Form.Item className="!mb-0 flex-1" name="carrier">
                    <SelectCarrier additionalOptions={[{ label: "Tous les transporteurs", value: "" }]} />
                </Form.Item>
            </div>
            <CustomizeTableDrawer
                id={customizeReturedSupportsListTableDrawerId}
                tableTemplateKey={TableTemplateKey.supportReturn}
            />
        </Form>
    );
};

export default ReturnedSupportsListFilters;
