/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M12 2c-.974 0-1.763.79-1.763 1.763v6.474H3.763a1.763 1.763 0 1 0 0 3.526h6.474v6.474a1.763 1.763 0 0 0 3.526 0v-6.474h6.474a1.763 1.763 0 1 0 0-3.526h-6.474V3.763C13.763 2.79 12.973 2 12 2"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPlus: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-plus", props.className)} />
));

export default SvgPlus;
