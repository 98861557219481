import { antdDrawer, create, useModal } from "@ebay/nice-modal-react";
import { DrawerProps, Typography } from "antd";
import { useCallback, useState } from "react";
import Button from "@oneui/button";

import CustomDrawer from "./CustomDrawer";
import {
    ReturnedSupportColumnKey,
    ShippingManagementColumnKey,
    TableTemplateItem,
    TableTemplateKey,
    TourOrderColumnKey,
} from "../queries/api/types";
import { useAuth } from "../context/AuthContext";
import { errorMessage, successMessage } from "../helpers/message";
import { defaultErrorMessageWithStatus } from "../i18n";
import {
    returnedSupportListColumnHeaderMap,
    shippingManagmentColumnHeaderMap,
    tourOrderListColumnHeaderMap,
} from "../i18n/tourOrders";
import { useTableTemplateUpdate } from "../queries/tableTemplates";
import SortableList from "./list/SortableList";

interface SortableColumn extends Omit<TableTemplateItem, "key"> {
    id: string;
    key: TourOrderColumnKey | ShippingManagementColumnKey | ReturnedSupportColumnKey;
}

interface CustomizeTableDrawerProps extends DrawerProps {
    tableTemplateKey: TableTemplateKey;
}

const CustomizeTableDrawer = create<CustomizeTableDrawerProps>(({ tableTemplateKey, ...props }) => {
    const { user } = useAuth();
    const modal = useModal();
    const onClose = () => {
        modal.hide();
    };
    const tableTemplate = user?.tableTemplates?.find((template) => template.key === tableTemplateKey);
    const [columsOrderChanged, setColumsOrderChanged] = useState(false);
    const [columnsToSave, setColumnsToSave] = useState<SortableColumn[]>(
        ([...(tableTemplate?.columns ?? [])].sort((a, b) => a.index - b.index) as SortableColumn[]).map((column) => ({
            ...column,
            id: `${column.index}`,
        }))
    );
    const { mutate: updateTemplate, isLoading } = useTableTemplateUpdate({
        onSuccess: () => {
            successMessage({ content: "Ordre des colonnes enregistré avec succès" });
            onClose();
        },
        onError: (error) => {
            errorMessage({ content: defaultErrorMessageWithStatus(error?.response?.status) });
        },
    });
    const onChangeOrder = (columns: SortableColumn[]) => {
        setColumsOrderChanged(true);
        setColumnsToSave(columns.map((col, index) => ({ ...col, index })));
    };
    const onSubmit = () => {
        updateTemplate({
            id: tableTemplate?.id,
            columns: columnsToSave,
        });
    };

    const getColumnHeaderTitle = useCallback(
        (key: SortableColumn["key"]) => {
            switch (tableTemplateKey) {
                case TableTemplateKey.tourOrders:
                    return tourOrderListColumnHeaderMap.get(key as TourOrderColumnKey);
                case TableTemplateKey.supportReturn:
                    return returnedSupportListColumnHeaderMap.get(key as ReturnedSupportColumnKey);
                case TableTemplateKey.loading:
                    return shippingManagmentColumnHeaderMap.get(key as ShippingManagementColumnKey);
                default:
                    return "";
            }
        },
        [tableTemplateKey]
    );

    return (
        <CustomDrawer
            title="Personnaliser le tableau"
            width={552}
            {...props}
            {...antdDrawer(modal)}
            placement={"right"}
            footer={
                <Button
                    size="large"
                    type="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    block
                    disabled={!columsOrderChanged}
                >
                    Enregistrer les modifications
                </Button>
            }
            footerStyle={{ border: "none", boxShadow: "none" }}
        >
            <Typography.Paragraph className="!mb-24">
                Vous pouvez modifier ci-dessous l’ordre des colonnes :
            </Typography.Paragraph>
            <SortableList<SortableColumn>
                data={columnsToSave}
                rowKey={(record) => `${record.id}`}
                isLoading={false}
                onOrderChange={onChangeOrder}
                renderItem={({ key }) => <span className="uppercase">{getColumnHeaderTitle(key)}</span>}
            />
        </CustomDrawer>
    );
});
export default CustomizeTableDrawer;
