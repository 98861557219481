/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 1c.74 0 1.342.6 1.342 1.342v11.785L17.49 9.98a1.341 1.341 0 1 1 1.897 1.897l-6.44 6.439a1.34 1.34 0 0 1-1.897 0l-6.439-6.44A1.341 1.341 0 1 1 6.51 9.98l4.149 4.149V2.342C10.659 1.6 11.259 1 12 1M1 21.66c0-.741.6-1.342 1.341-1.342H21.66a1.341 1.341 0 1 1 0 2.683H2.34C1.601 23 1 22.4 1 21.66"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgDownload: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-download", props.className)} />
));

export default SvgDownload;
