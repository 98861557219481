/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.42 2.092A1 1 0 0 0 1 3v15a1 1 0 0 0 .58.908l6.493 2.997a1 1 0 0 0 .828.011l6.584-2.821 6.096 2.813A1 1 0 0 0 23 21V6a1 1 0 0 0-.58-.908l-6.496-2.997a1 1 0 0 0-.824-.011L8.515 4.906zM14.5 4.517l-5 2.143v12.824l5-2.143zm2 12.843 4.5 2.077V6.64l-4.5-2.077zM3 4.563 7.5 6.64v12.797L3 17.36z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMapDraw: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-map-draw", props.className)} />
));

export default SvgMapDraw;
