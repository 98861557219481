/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M16.289 2.44a1.453 1.453 0 0 0-2.082 0 1.52 1.52 0 0 0 0 2.12L17.582 8H3.472C2.659 8 2 8.672 2 9.5c0 .829.659 1.5 1.472 1.5h17.664c.595 0 1.132-.365 1.36-.926a1.52 1.52 0 0 0-.32-1.634zM3.864 13c-.595 0-1.132.366-1.36.926a1.52 1.52 0 0 0 .32 1.635l5.887 6a1.453 1.453 0 0 0 2.082 0 1.52 1.52 0 0 0 0-2.121L7.418 16h14.11C22.341 16 23 15.33 23 14.5c0-.828-.659-1.5-1.472-1.5z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSwitch: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-switch", props.className)} />
));

export default SvgSwitch;
