/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.913 4.93c3.914-3.906 10.26-3.906 14.174 0a9.95 9.95 0 0 1 2.686 4.85 1.222 1.222 0 0 1-2.384.54 7.5 7.5 0 0 0-2.03-3.666 7.59 7.59 0 0 0-10.717 0A44 44 0 0 0 4.584 8.83h3.505a1.22 1.22 0 0 1 1.222 1.219 1.22 1.22 0 0 1-1.222 1.22H2.222A1.22 1.22 0 0 1 1 10.048V4.195c0-.673.547-1.22 1.222-1.22a1.22 1.22 0 0 1 1.222 1.22v2.252c.412-.444.898-.947 1.47-1.518m-1.765 7.832a1.22 1.22 0 0 1 1.463.92 7.5 7.5 0 0 0 2.03 3.665 7.59 7.59 0 0 0 10.717 0 44 44 0 0 0 2.058-2.176h-3.505a1.22 1.22 0 0 1-1.222-1.22c0-.673.547-1.219 1.222-1.219h5.867A1.22 1.22 0 0 1 23 13.952v5.853a1.22 1.22 0 0 1-1.222 1.22 1.22 1.22 0 0 1-1.222-1.22v-2.251c-.412.444-.898.947-1.47 1.517-3.913 3.906-10.259 3.906-14.173 0a9.95 9.95 0 0 1-2.686-4.85 1.22 1.22 0 0 1 .921-1.459"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSync: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-sync", props.className)} />
));

export default SvgSync;
