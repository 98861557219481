/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.06 1.038a.99.99 0 0 1 1.157.528A1.99 1.99 0 0 0 12 2.699c.776 0 1.451-.459 1.783-1.133a.99.99 0 0 1 1.158-.528 10.9 10.9 0 0 1 4.548 2.638c.323.312.411.807.216 1.217-.131.275-.205.585-.205.915 0 1.145.895 2.073 2 2.073h.028c.442-.006.836.289.968.726a11.853 11.853 0 0 1 .28 5.716c-.102.52-.567.876-1.077.824a2 2 0 0 0-.199-.01c-1.105 0-2 .928-2 2.073 0 .51.176.975.47 1.336.33.406.312 1.005-.043 1.387a10.94 10.94 0 0 1-4.784 3.024c-.521.16-1.072-.14-1.237-.678-.258-.837-1.016-1.44-1.906-1.44s-1.648.603-1.906 1.44c-.165.538-.716.839-1.237.678a10.94 10.94 0 0 1-4.784-3.024 1.065 1.065 0 0 1-.043-1.387c.294-.361.47-.826.47-1.336 0-1.145-.895-2.073-2-2.073q-.101 0-.2.01a1.005 1.005 0 0 1-1.077-.824A12 12 0 0 1 1 12.028c0-1.19.176-2.34.504-3.421.132-.437.526-.732.968-.726H2.5c1.105 0 2-.928 2-2.073 0-.33-.074-.64-.205-.915a1.06 1.06 0 0 1 .216-1.217A10.9 10.9 0 0 1 9.06 1.038M6.367 4.75q.13.51.132 1.058c0 2.029-1.405 3.717-3.261 4.076a9.7 9.7 0 0 0-.18 3.22c1.945.282 3.441 2.013 3.441 4.106 0 .663-.15 1.29-.418 1.846.75.68 1.61 1.231 2.546 1.624.71-1.151 1.954-1.915 3.372-1.915s2.661.764 3.372 1.915a9 9 0 0 0 2.546-1.623 4.26 4.26 0 0 1-.418-1.847c0-2.093 1.496-3.824 3.44-4.106a9.8 9.8 0 0 0-.178-3.22c-1.857-.36-3.262-2.047-3.262-4.076q.002-.55.132-1.058a8.9 8.9 0 0 0-2.545-1.488c-.732.92-1.843 1.51-3.087 1.51s-2.354-.59-3.087-1.51A8.9 8.9 0 0 0 6.368 4.75M7.5 12.028c0-2.577 2.015-4.665 4.5-4.665s4.5 2.088 4.5 4.665-2.015 4.664-4.5 4.664-4.5-2.088-4.5-4.664M12 9.436c-1.38 0-2.5 1.16-2.5 2.592 0 1.43 1.12 2.59 2.5 2.59s2.5-1.16 2.5-2.59c0-1.432-1.12-2.592-2.5-2.592"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSettings: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-settings", props.className)} />
));

export default SvgSettings;
