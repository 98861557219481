/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M7 13a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1M14 12a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2zM7 17a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1M14 16a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a1 1 0 0 0-.293-.707l-5-5A1 1 0 0 0 15 1zm7 2H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9h-3a3 3 0 0 1-3-3zm5.586 4H16a1 1 0 0 1-1-1V3.414z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgData: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-data", props.className)} />
));

export default SvgData;
