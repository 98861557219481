/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.505 6.517a1.696 1.696 0 0 1 2.438 0L12 13.74l7.057-7.223a1.696 1.696 0 0 1 2.438 0c.673.69.673 1.807 0 2.496l-8.276 8.47c-.673.69-1.765.69-2.438 0l-8.276-8.47a1.794 1.794 0 0 1 0-2.496"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevronDown: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron-down", props.className)} />
));

export default SvgChevronDown;
