/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillOpacity={0.85}
            fillRule="evenodd"
            d="M1.115 6.535a1 1 0 0 1 .445-.433l9.993-4.996a1 1 0 0 1 .894 0l9.993 4.996a1 1 0 0 1 .56.918V17a1 1 0 0 1-.553.894l-9.982 4.992a1 1 0 0 1-.93 0l-9.982-4.992A1 1 0 0 1 1 17V7.02a1 1 0 0 1 .115-.485M17 5.618 19.764 7 12 10.882 9.236 9.5zM14.764 4.5 12 3.118 4.236 7 7 8.382zm-8.232 5.884L3 8.618v7.764l8 4v-7.764l-4.427-2.214zM13 20.382v-7.764l8-4v7.764z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPackage: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-package", props.className)} />
));

export default SvgPackage;
