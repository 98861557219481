import { useState, useEffect } from "react";

type Dimensions = {
    width: number;
    height: number;
};

export function useElementDimensions<T extends HTMLElement>(elementRef: React.RefObject<T>): Dimensions | null {
    const [dimensions, setDimensions] = useState<Dimensions | null>(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (elementRef.current) {
                const { offsetWidth, offsetHeight } = elementRef.current;
                setDimensions({
                    width: offsetWidth,
                    height: offsetHeight,
                });
            }
        };

        // Initial update
        updateDimensions();

        // Add a resize observer for dynamic updates
        const resizeObserver = new ResizeObserver(() => updateDimensions());
        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef]);

    return dimensions;
}
