import { VFC } from "react";

import ColorTag from "../../../components/ColorTag";
import { getTourOrderStatusTagColor } from "../../../helpers";
import { tourOrderStatusMap } from "../../../i18n/tourOrders";
import { TourOrder } from "../../../queries/api/types";

interface ReturnedSupportStatusTagProps {
    tourOrder: TourOrder;
}

const ReturnedSupportStatusTag: VFC<ReturnedSupportStatusTagProps> = ({ tourOrder }) => {
    return (
        <ColorTag color={getTourOrderStatusTagColor(tourOrder)}>{tourOrderStatusMap.get(tourOrder.status)}</ColorTag>
    );
};

export default ReturnedSupportStatusTag;
