import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    details,
    list,
    TableTemplateIdPayload,
    TableTemplateListPayload,
    TableTemplateListResponse,
    TableTemplateUpdatePayload,
    remove,
    update,
} from "./api/tableTemplates";
import { TableTemplate } from "./api/types";

export const tableTemplatesKeys = {
    all: ["tableTemplates"],
    lists: () => [...tableTemplatesKeys.all, "list"],
    list: (params?: TableTemplateListPayload) => [...tableTemplatesKeys.lists(), params],
    details: () => [...tableTemplatesKeys.all, "details"],
    detail: (id?: TableTemplateIdPayload) => [...tableTemplatesKeys.details(), id],
};

export const useTableTemplateList = <TData = TableTemplateListResponse>(
    params?: TableTemplateListPayload,
    options?: UseQueryOptions<TableTemplateListResponse, AxiosError, TData>
) => {
    return useQuery<TableTemplateListResponse, AxiosError, TData>(
        tableTemplatesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTableTemplateDetails = <TData = TableTemplate>(
    id?: TableTemplateIdPayload,
    options?: UseQueryOptions<TableTemplate, AxiosError, TData>
) => {
    return useQuery<TableTemplate, AxiosError, TData>(
        tableTemplatesKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useTableTemplateUpdate = (
    options?: UseMutationOptions<TableTemplate, AxiosError, TableTemplateUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<TableTemplate, AxiosError, TableTemplateUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(tableTemplatesKeys.detail(variables.id));

            // invalidate /me query to update table template populate
            queryClient.invalidateQueries(["auth-user"]);
        },
    });
};

export const useTableTemplateRemove = (options?: UseMutationOptions<undefined, AxiosError, TableTemplateIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TableTemplateIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(tableTemplatesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(tableTemplatesKeys.lists());
        },
    });
};
