/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <g clipPath="url(#phone_svg__a)">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6.812 2.75a1.07 1.07 0 0 0-.964 0c-.114.058-.256.181-.882.808l-.169.168c-.598.598-.76.771-.888 1-.15.267-.275.748-.274 1.053.001.271.041.435.218 1.057a19.26 19.26 0 0 0 4.93 8.38 19.26 19.26 0 0 0 8.382 4.932c.621.176.785.216 1.056.217.305 0 .787-.124 1.052-.274.23-.129.403-.29 1.001-.888l.168-.168c.627-.627.75-.77.808-.883.153-.303.153-.66 0-.964-.058-.114-.181-.256-.808-.883l.755-.755-.755.755-.208-.208c-.412-.411-.504-.496-.574-.541-.354-.23-.81-.23-1.164 0-.07.045-.163.13-.575.541l-.025.025c-.096.097-.217.218-.363.323l-.621-.868.621.868a2.18 2.18 0 0 1-1.84.314 2.7 2.7 0 0 1-.422-.171l-.026-.012a16.7 16.7 0 0 1-4.574-3.247 16.7 16.7 0 0 1-3.246-4.574l-.013-.025c-.052-.11-.12-.251-.17-.422l1.025-.299-1.025.299a2.18 2.18 0 0 1 .314-1.841c.104-.146.226-.267.322-.363l.025-.025c.412-.412.496-.504.542-.574.23-.354.23-.81 0-1.164-.046-.07-.13-.163-.542-.575l-.208-.208c-.627-.627-.769-.75-.883-.807M4.884.846a3.2 3.2 0 0 1 2.892 0c.462.234.863.636 1.327 1.1l.102.103.208.208.067.067c.306.306.57.57.755.854l-.895.582.895-.582c.69 1.061.69 2.43 0 3.492-.185.284-.449.547-.755.853l-.182.183-.002.012.015.033.039.08a14.5 14.5 0 0 0 2.831 3.99 14.5 14.5 0 0 0 3.989 2.83l-.463.963.462-.962.114.053h.006l.006-.002a7 7 0 0 0 .183-.182c.306-.306.57-.57.854-.755a3.2 3.2 0 0 1 3.492 0c.284.185.547.449.853.755l.067.068-.755.754.755-.755.208.209.102.102c.466.464.868.864 1.101 1.326.46.91.46 1.983 0 2.892-.233.462-.635.863-1.1 1.326l-.103.103-.168.168-.072.072c-.497.497-.878.879-1.393 1.168a4.66 4.66 0 0 1-2.104.547c-.59-.002-1.02-.124-1.573-.281l-.06-.017a21.4 21.4 0 0 1-9.308-5.476A21.4 21.4 0 0 1 1.798 7.42l-.017-.06c-.157-.555-.28-.984-.281-1.574a4.66 4.66 0 0 1 .547-2.104c.29-.515.67-.896 1.168-1.392l.072-.073.169-.168.102-.102c.464-.465.865-.867 1.326-1.1l.477.941z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="phone_svg__a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPhone: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-phone", props.className)} />
));

export default SvgPhone;
