import { Badge, Typography } from "antd";
import { FC } from "react";
import { TitleProps } from "antd/lib/typography/Title";
import { Link, LinkProps } from "react-router-dom";

import "../assets/styles/ListTitle.less";

import { ArrowLeft } from "./icons";
import { classNames } from "../helpers";

interface ListTitleProps extends TitleProps {
    count?: number;
    backRoute?: LinkProps["to"];
    alt?: boolean;
}

const ListTitle: FC<ListTitleProps> = ({ children, count, level = 1, backRoute, alt, ...props }) => {
    return (
        <div className="flex items-center list-title">
            {backRoute && (
                <Link to={backRoute} className="mr-16">
                    <ArrowLeft className="text-lg leading-5" />
                </Link>
            )}
            <Typography.Title
                level={level}
                {...props}
                className={classNames(props.className, "flex items-center font-bold !text-[30px]", alt && "text-blue")}
            >
                {children}
                <Badge count={count ?? 0} overflowCount={999999} showZero />
            </Typography.Title>
        </div>
    );
};

export default ListTitle;
