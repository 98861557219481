/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.483 21.495c.69-.673.69-1.765 0-2.438L10.26 12l7.223-7.056c.69-.674.69-1.765 0-2.439a1.794 1.794 0 0 0-2.495 0l-8.471 8.276a1.696 1.696 0 0 0 0 2.438l8.47 8.276c.69.674 1.807.674 2.496 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevronLeft: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron-left", props.className)} />
));

export default SvgChevronLeft;
