/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.289 3.883a3.006 3.006 0 0 0-4.251.004l-8.57 8.57a5.003 5.003 0 1 0 7.075 7.076l9.19-9.19a1 1 0 0 1 1.414 1.414l-9.19 9.19a7.004 7.004 0 0 1-9.904-9.904l8.57-8.57a5.01 5.01 0 0 1 7.079-.005 5.006 5.006 0 0 1 .006 7.079l-8.59 8.57a3.001 3.001 0 0 1-4.245-4.244l8.49-8.48a1 1 0 1 1 1.414 1.415l-8.49 8.48a1.002 1.002 0 0 0 1.416 1.415l8.59-8.57a3.006 3.006 0 0 0-.004-4.25"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPaperclip: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-paperclip", props.className)} />
));

export default SvgPaperclip;
