/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.5 1a1 1 0 0 1 1 1v1h5V2a1 1 0 1 1 2 0v1H21a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4.5V2a1 1 0 0 1 1-1m-1 4H3v4h18V5h-4.5v1a1 1 0 1 1-2 0V5h-5v1a1 1 0 1 1-2 0zM21 11h-4.5v4H21zm0 6h-4.5v4H21zm-6.5 4v-4h-5v4zm-7 0v-4H3v4zM3 15h4.5v-4H3zm6.5-4v4h5v-4z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCalendar: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-calendar", props.className)} />
));

export default SvgCalendar;
