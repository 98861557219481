/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.759 3H17.24c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.239.469.339.966.387 1.506a1 1 0 0 1 .03.5c.02.45.02.97.02 1.569v6.483c0 .805 0 1.469-.045 2.01-.046.562-.145 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.248-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H6.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.144-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C1 16.711 1 16.047 1 15.242V8.759c0-.598 0-1.119.018-1.57a1 1 0 0 1 .031-.5c.048-.539.148-1.036.387-1.505a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C5.29 3 5.954 3 6.758 3M3 8.92v6.28c0 .383 0 .712.004.998L7.54 12.1zM9.7 11.17 3.083 6.538c.036-.208.082-.342.135-.446a2 2 0 0 1 .874-.874c.157-.08.38-.144.82-.18C5.361 5 5.943 5 6.8 5h10.4c.857 0 1.439.001 1.889.038.439.036.663.1.82.18a2 2 0 0 1 .873.874c.053.104.1.238.135.446l-6.615 4.63-.035.025-1.005.704c-.73.51-.884.598-1.02.632a1 1 0 0 1-.484 0c-.136-.034-.29-.122-1.02-.633l-1.005-.703zm-.477 2.107-5.66 5.113q.23.24.53.393c.157.08.38.145.82.18C5.361 19 5.943 19 6.8 19h10.4c.857 0 1.439 0 1.889-.037.439-.036.663-.101.82-.18q.299-.154.53-.394l-5.66-5.113-.37.259-.116.08c-.544.383-1.023.72-1.567.855a3 3 0 0 1-1.452 0c-.544-.136-1.022-.472-1.567-.854l-.115-.081zM16.46 12.1l4.538 4.098c.003-.286.003-.615.003-.998V8.92z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMail: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-mail", props.className)} />
));

export default SvgMail;
