/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.2 4.8a5.4 5.4 0 1 0 0 10.8 5.4 5.4 0 0 0 0-10.8M3 10.2a7.2 7.2 0 1 1 12.888 4.415l4.848 4.849a.9.9 0 1 1-1.272 1.273l-4.849-4.849A7.2 7.2 0 0 1 3 10.2"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.2 5.3a4.9 4.9 0 1 0 0 9.8 4.9 4.9 0 0 0 0-9.8m-7.7 4.9a7.7 7.7 0 1 1 14.044 4.365l4.546 4.545a1.4 1.4 0 0 1-1.98 1.98l-4.545-4.545A7.7 7.7 0 0 1 2.5 10.2m12.115 5.688 4.849 4.849a.9.9 0 1 0 1.272-1.273l-4.848-4.849a7.2 7.2 0 1 0-1.273 1.273M4.8 10.2a5.4 5.4 0 1 1 10.8 0 5.4 5.4 0 0 1-10.8 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSearch: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-search", props.className)} />
));

export default SvgSearch;
